import { render, staticRenderFns } from "./SearchWidget.vue?vue&type=template&id=f7d0d3ce&"
import script from "./SearchWidget.vue?vue&type=script&lang=ts&"
export * from "./SearchWidget.vue?vue&type=script&lang=ts&"
import style0 from "./SearchWidget.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./SearchWidget.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchTab: require('/codebuild/output/src2953753390/src/frontend/components/search/SearchTab.vue').default,SearchTabAlert: require('/codebuild/output/src2953753390/src/frontend/components/search/SearchTabAlert.vue').default,SearchTabEmpty: require('/codebuild/output/src2953753390/src/frontend/components/search/SearchTabEmpty.vue').default,SearchTabContent: require('/codebuild/output/src2953753390/src/frontend/components/search/SearchTabContent.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VAutocomplete,VTabItem,VTabs})
